import React from "react";
import Box from "@mui/material/Box";
import { AppBar, CircularProgress, Menu, MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LogoutButton from "../LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";

function UnauthorizedHeader() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <CircularProgress />;
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "black", width: "100%", marginBottom: "1%" }}
    >
      <Box sx={{ margin: "auto", width: "80%" }}>
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box
            component="img"
            sx={{ width: 150 }}
            alt="WeWork"
            src={window.location.origin + "/logo.png"}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box sx={{ flexGrow: 0, textAlign: "left" }}>
              <Tooltip title="Open settings">
                <Box onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  Logged in as {user?.name}
                </Box>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key="logout" onClick={handleCloseUserMenu}>
                  <Typography component={"div"} textAlign="center">
                    <LogoutButton />
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}

export default UnauthorizedHeader;
