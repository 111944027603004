import React from "react";
import UnauthorizedHeader from "../modules/UnauthorizedHeader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

interface UnauthorizedPageProps {
  errorMessage: string;
}

function UnauthorizedPage(props: UnauthorizedPageProps) {
  const { errorMessage } = props;

  return (
    <>
      <UnauthorizedHeader />
      <Container maxWidth="sm">
        <Box
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h1" color="error">
            401
          </Typography>
          <Typography variant="h3" gutterBottom>
            Unauthorized
          </Typography>
          <Typography variant="body1">
            Failed to load user role: <b>{errorMessage}</b>
            <br />
            Please try reloading the page or logging in again.
          </Typography>
        </Box>
      </Container>
    </>
  );
}

export default UnauthorizedPage;
