import React from "react";
import Header from "../modules/Header";
import { Box } from "@mui/material";
import UserRolesTable from "../modules/UserRolesTable";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function UserManagementPage() {
  return (
    <>
      <Header />
      {
        <Box sx={{ margin: "auto", width: "60%" }}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            component="h1"
            variant="h4"
            align="center"
            margin={3}
          >
            <InfoOutlinedIcon fontSize="medium" color="primary" />
            Users:
          </Typography>
          <UserRolesTable />
        </Box>
      }
    </>
  );
}

export default UserManagementPage;
