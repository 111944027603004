import React from "react";
import { UserPublicDataType } from "../types/authTypes";
import { Entities } from "../types/types";
import NoAccessPage from "../components/pages/NoAccessPage";

interface ProtectedRouteProps {
  user: UserPublicDataType;
  permissions: string[];
  entity: Entities;
  Component: React.ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (
  props: ProtectedRouteProps,
) => {
  const { user, permissions, entity, Component } = props;

  const hasPermissions = permissions.every(
    (permission) =>
      user.user_permissions[permission as keyof typeof user.user_permissions],
  );

  if (!hasPermissions) {
    return <NoAccessPage entity={entity} />;
  }

  return <Component />;
};

export default ProtectedRoute;
