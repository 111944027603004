import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import MainPage from "./components/pages/MainPage";
import DevicesPage from "./components/pages/DevicesPage";
import CoreDevicesPage from "./components/pages/CoreDevicesPage";
import DistroDHCPDevicesPage from "./components/pages/DistroDHCPDevicesPage";
import DistroDevicesPage from "./components/pages/DistroDevicesPage";
import AccessSwitchesPage from "./components/pages/AccessSwitchesPage";
import UserManagementPage from "./components/pages/UserManagementPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import { CircularProgress } from "@mui/material";
import { ApiInstance } from "./api/api";
import { utils_instance } from "./utils/Utils";
import { UserPublicDataType } from "./types/authTypes";
import { Entities } from "./types/types";
import { UserContext } from "./context/userConext";
import ProtectedRoute from "./routes/ProtectedRoute";
import UnauthorizedPage from "./components/pages/UnauthorizedPage";

const _App: React.FC = () => {
  const {
    isAuthenticated,
    user,
    loginWithRedirect,
    isLoading,
    error,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const [userInfo, setUserInfo] = React.useState<
    UserPublicDataType | undefined
  >();
  const [userStatusProgress, setUserStatusProgress] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

  let token = localStorage.getItem("token");

  const getUserInfo = async () => {
    try {
      const user = await ApiInstance.getUserInfo();
      setUserInfo(user);
      setUserStatusProgress(false);
    } catch (e: any) {
      if (e.response?.data?.detail) {
        setErrorMessage(e.response?.data?.detail);
      } else {
        setErrorMessage("Unknown error");
      }
    }
  };

  const loadData = async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();

      localStorage.setItem("token", token);
      ApiInstance.createAxiosInstance(token);

      getUserInfo();
    }

    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }

    if (token) {
      utils_instance.checkIsValidToken(token, loginWithRedirect);
    }
  };

  useEffect(() => {
    loadData();
  }, [isAuthenticated, isLoading, token]);

  if ((!token && userStatusProgress) || !userInfo) {
    return errorMessage ? <UnauthorizedPage errorMessage={errorMessage} /> : <CircularProgress />;
  }

  return (
    <UserContext.Provider value={userInfo}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute
                user={userInfo}
                permissions={["read_buildings_data_permission"]}
                entity={Entities.buildings}
                Component={MainPage}
              />
            }
          />
          <Route
            path="/devices/:building"
            element={
              <ProtectedRoute
                user={userInfo}
                permissions={["read_building_devices_data_permission"]}
                entity={Entities.building_manager}
                Component={DevicesPage}
              />
            }
          />
          <Route
            path="/devices/core/:core"
            element={
              <ProtectedRoute
                user={userInfo}
                permissions={["read_core_devices_data_permission"]}
                entity={Entities.core_manager}
                Component={CoreDevicesPage}
              />
            }
          />
          <Route
            path="/devices/distro/:distro"
            element={
              <ProtectedRoute
                user={userInfo}
                permissions={[
                  "read_distro_devices_arp_data_permission",
                  "read_stack_devices_data_permission",
                ]}
                entity={Entities.distro_manager}
                Component={DistroDevicesPage}
              />
            }
          />
          <Route
            path="/devices/distro-dhcp/:distro"
            element={
              <ProtectedRoute
                user={userInfo}
                permissions={[
                  "read_distro_devices_arp_data_permission",
                  "read_stack_devices_data_permission",
                ]}
                entity={Entities.distro_dhcp_manager}
                Component={DistroDHCPDevicesPage}
              />
            }
          />
          <Route
            path="/devices/switch/:access_switch"
            element={
              <ProtectedRoute
                user={userInfo}
                permissions={[
                  "read_stack_devices_data_permission",
                  "read_switch_devices_data_permission",
                ]}
                entity={Entities.access_manager}
                Component={AccessSwitchesPage}
              />
            }
          />
          <Route
            path="/management/users"
            element={
              <ProtectedRoute
                user={userInfo}
                permissions={["user_management_permission"]}
                entity={Entities.user_management}
                Component={UserManagementPage}
              />
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
};

function App() {
  return <_App />;
}

export default App;
