import React from "react";
import Header from "../modules/Header";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Entities } from "../../types/types";
import Link from "@mui/material/Link";

interface NoAccessPageProps {
  entity: Entities;
}

function NoAccessPage(props: NoAccessPageProps) {
  const { entity } = props;
  const hqitUrl: string = "https://jira.weworkers.io/plugins/servlet/desk/portal/118/create/1808";

  return (
    <>
      <Header />
      <Container maxWidth="sm">
        <Box
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h1" color="error">
            403
          </Typography>
          <Typography variant="h3" gutterBottom>
            Forbidden
          </Typography>
          <Typography variant="body1">
            You do not have permission to access the <b>{entity}</b> page.{" "}
            <br />
            To request access, please submit a{" "}
            <Link
              href={hqitUrl}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              HQIT ticket
            </Link>{" "}
            requesting desired role.
          </Typography>
        </Box>
      </Container>
    </>
  );
}

export default NoAccessPage;
