import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ApiInstance } from "../../api/api";
import { UserListDataType } from "../../types/types";
import TablePlaceholder from "../placeholders/TablePlaceholder";
import BuildingProcessingError from "../placeholders/BuildingProcessingError";

export default function UserRolesTable() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dense, setDense] = React.useState(false);
  const [users, setUsers] = React.useState<UserListDataType>(
    {} as UserListDataType,
  );
  const [apiErrorFlag, setApiErrorFlag] = React.useState<Boolean>(false);
  const columnLabels = ["User", "Roles"];

  const getUsers = async () => {
    try {
      setIsLoading(true);
      const usersWithRoles = await ApiInstance.getUsersWithRoles();

      setUsers(usersWithRoles.users);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      setApiErrorFlag(true);
    }
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const renderRoles = (roles: string[]) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "12pt" }}>
      {roles.map((role) => (
        <Chip key={role} label={role} color="default" size="small" />
      ))}
    </Box>
  );

  const sortedUsers = Object.entries(users).sort(([emailA], [emailB]) => {
    return emailA.localeCompare(emailB);
  });

  return (
    <Box sx={{ width: "100%", margin: "5pt" }}>
      <Box sx={{ width: "100%", margin: "5pt" }}>
        {isLoading ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <TablePlaceholder rows={15} columns={columnLabels} />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="users"
              size={dense ? "small" : "medium"}
            >
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Roles</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(([email, roles]) => (
                    <TableRow key={email}>
                      <TableCell component="th" scope="row">
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <PersonIcon style={{ marginRight: "8pt" }} />
                          </Grid>
                          <Grid item>{email}</Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>{renderRoles(roles)}</TableCell>
                    </TableRow>
                  ))}
                {apiErrorFlag && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <BuildingProcessingError entity={"user list"} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                20,
                { value: sortedUsers.length, label: "All" },
              ]}
              component="div"
              count={sortedUsers.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Rows per table:"
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </Box>
  );
}
